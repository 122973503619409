<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{ name: 'meters' }" class="button">
        Manage Meters
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Meter NO" horizontal>
              <b-input
                v-model="form.name"
                placeholder="e.g. Name"
                required
              />
            </b-field>
            <b-field label="Meter Slug" horizontal>
              <b-input
                v-model="form.mnum"
                placeholder="e.g. Slug"
                required
              />
            </b-field>
            <b-field label="Prev Read" horizontal>
              <b-input
                v-model="form.prev_read"
                placeholder="e.g. read"
                required
              />
            </b-field>
            <b-field label="Prev Date" horizontal>
              <b-datepicker :value="form.prev_date ? new Date(form.prev_date) : new Date()" @input="selectedDate">
              </b-datepicker>
            </b-field>
            <b-field label="Prev Date (Extra)" message="Verify the selected date here (YYYY-MM-DD)." horizontal>
              <b-input
                v-model="form.prev_date"
                placeholder="e.g. Date"
                required
                readonly
              />
            </b-field>
            <b-field label="Consumer" horizontal>
              <v-select  label="text" placeholder="Search Consumer" :filterable="false" class="style-chooser" v-model="form.consumer" :options="consumers" @search="onConsumerSearch">
                    <template slot="no-options">
                    type to search consumer ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>

            <b-field label="Prev Status" horizontal>
                <b-select placeholder="Is Active?" v-model="form.prev_status">
                    <option value="1">Active</option>
                    <option value="0">Faulty</option>
                </b-select>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Meter Details"
          icon="account"
          class="tile is-child"
          header-icon="delete"
          @header-icon-click="trashModal(form)"
        >
          <b-field label="Id">
            <b-input :value="form.id" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Meter Number">
            <b-input :value="form.mnum" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Prev Read">
            <b-input :value="form.prev_read" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Prev Date">
            <b-input :value="form.prev_date" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Consumer">
            <b-input :value="form.consumer? form.consumer.text : ''" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created At">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Updated At">
            <b-input
              :value="form.updated_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import ModalBox from '@/components/ModalBox'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import { apiService } from '@/services'

export default {
  name: 'MeterForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ModalBox,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isModalActive: false,
      trashObject: null,
      createdReadable: null,
      isProfileExists: false,
      consumers: []
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    titleStack () {
      const lastCrumb = this.isProfileExists ? this.form.name : 'New Meter'
      return ['Admin', 'Meters', lastCrumb]
    },
    heroTitle () {
      return this.isProfileExists ? this.form.name : 'Create Meter'
    },
    formCardTitle () {
      return this.isProfileExists ? 'Edit Meter' : 'New Meter'
    },
    ...mapState('meter', ['item', 'formError'])
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false
      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('meter', ['getItem', 'addItem', 'updateItem', 'deleteItem']),
    getClearFormObject () {
      return {
        id: null,
        name: null,
        mnum: null,
        consumer: null,
        prev_read: null,
        prev_date: null,
        prev_status: null,
        created_at: null,
        updated_at: null
      }
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id).then(() => {
        this.$router.replace({ name: 'meters' })
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    getData () {
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id).then(() => {
          // console.log(this.item)
          if (!isEmpty(this.item)) {
            this.isProfileExists = true
            this.form = this.item
          } else {
            this.$router.push({ name: 'meter.new' })
          }
        })
      }
    },
    submit () {
      this.isLoading = true
      if (!this.form.id) {
        this.addItem(this.form).then(() => {
          if (!this.formError) this.$router.push({ name: 'meters' })
          this.isLoading = false
        }).catch(() => { this.isLoading = false })
      } else {
        this.updateItem(this.form).then(() => {
          this.isLoading = false
          this.form = this.item
        }).catch(() => { this.isLoading = false })
      }
    },
    selectedDate (val) {
      this.form.prev_date = dayjs(val).format('YYYY-MM-DD')
    },
    onConsumerSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'consumers', search, this)
      }
    },
    search: debounce((loading, endpoint, search, vm) => {
      apiService.getAll(`/options/${escape(endpoint)}?q=${escape(search)}`).then(response => {
        vm[endpoint] = response.data
        loading(false)
      }).catch(error => {
        vm.showError(error)
      })
    }, 350)
  }
}
</script>
